export default class TimeEntryPayoutEntity {
    static getEntityData(elem: any) {
        return {
            uuid: elem.querySelector("#time_entry_payout_uuid") ? (elem.querySelector("#time_entry_payout_uuid") as HTMLInputElement).value : null,
            date: elem.querySelector("#time_entry_payout_date").value,
            minutes: elem.querySelector("#time_entry_payout_minutes").value,
            user_id: elem.querySelector("#new_time_entry_payout_user_id") ? (elem.querySelector("#new_time_entry_payout_user_id option:checked") as HTMLInputElement).value : (elem.querySelector("#time_entry_payout_user_id option:checked") as HTMLInputElement).value,

        }
    }
}